// .safty-section {
//     .section-title {
//         text-align: center;
//         max-width: 600px;
//         margin: 0 auto 50px;

//         h2 {
//             max-width: inherit;
//         }
//     }

//     .safety-video {
//         max-width: 750px;
//         margin: auto;
//     }
// }

@import '../../sass//colors.scss';

.safty-section {
    padding: 100px 0 70px 0px;

    .section-title {
        margin-bottom: 30px;

        h2 {
            max-width: inherit;
        }
    }

    .safty-text {
        ul {
            padding: 0;
            margin: 0;

            li {
                list-style: none;
                margin-bottom: 10px;
                font-weight: 500;

                svg {
                    color: $color-blue;
                    margin-right: 15px;
                    top: -1px;
                    position: relative;
                }
            }
        }
    }
}

.safty-img {
        text-align: left;
        margin-right: 60px;
        margin-left: 60px;
        position: relative;
        z-index: 1;
        margin-bottom: 30px;

        img {
            border-radius: 30px;
        }

        &::before {
            position: absolute;
            content: "";
            width: 45%;
            height: 125%;
            top: -12%;
            right: 0%;
            background: #FADBE2;
            z-index: -1;
            border-radius: 20px;
        }
}

// Mobile Responsive
@media (max-width: 575px) {
    .safty-section {
        padding: 50px 15px;

        .safty-text {
            ul {
                margin-bottom: 10px;
                
                li {
                    margin-bottom: 8px;
                    font-size: 14px;

                    svg {
                        margin-right: 5px;
                    }
                }
            }
        }

        .safty-img {
            margin-right: 0;
            margin-left: 0;

            img {
                border-radius: 30px;
            }

            &::before {
                display: none;
            }
        }
    }
}

@media (min-width: 576px) and (max-width:767px){
    .safty-section {
        padding: 50px 0;

        .safty-text {
            margin-bottom: 20px;
        }

        .safty-img {
            margin-right: 0px;
            margin-left: 0;

            img {
                border-radius: 30px;
            }

            &::before {
                display: none;
            }
        }
    }
}

// Medium Devices Responsive
@media (min-width: 768px) and (max-width:991px){
    .safty-section {
        padding: 50px 0;

        .safty-img {
            margin-right: 20px;
            margin-left: 20px;

            img {
                border-radius: 30px;
            }

            &::before {
                right: -10%;
            }
        }
    }
}

@media (min-width: 992px) and (max-width:1199px){
    .safty-section {
        padding: 70px 0;

        .safty-img {
            margin-right: 20px;
            margin-left: 20px;

            img {
                border-radius: 30px;
            }

            &::before {
                right: -10%;
            }
        }
    }
}

// Large Device Responsive
@media (min-width: 1200px){
    .safty-section {
        .safty-img {
            
            img {
                border-radius: 30px;
            }

            &::before {
                right: -10%;
            }
        }
    }
}